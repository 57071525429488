.orders {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .cart-item-data {

        .cart-item-label {
            h3 {
                font-size: 18px;
                font-weight: 700;
                line-height: 21.78px;
                margin: 0;
            }

            .info-order {
                font-weight: 400;

                span {
                    display: inline;
                    margin: 0;
                }
            }
        }

        .price-orders {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
        }
    }



}

.wrapper-content {
    padding: 10px;
}

.card-block-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;

    .cart-item-row {
        overflow: hidden;
        display: flex;
        background-color: #2B2B2B;
        border-radius: 10px;
        padding: 17px 31px;
        position: relative;
        min-height: 140px;

        .cart-item-picture {
            position: absolute;
            top: 0;
            left: 0;
            width: 125px;
            height: 140px;



            img {
                width: 125px;
                height: 140px;
                border-radius: 10px;
                object-fit: cover;
            }
        }

        .closeIcon {
            height: 15px;
            width: 15px;
        }

        .cart-item-data {
            margin-left: 172px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .cart-right-block {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 30px;

                .cart-item-counter {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    width: 125px;
                    background-color: #222222;
                    align-items: center;
                    border-radius: 9px;
                    height: 42px;
                    color: white;
                }
            }

            .cart-item-price {
                color: #1FB73A;
                font-size: 24px;
                line-height: 21px;
            }
        }

        .cart-item-label {
            font-size: 18px;
            color: white;


            .cart-item-caption {
                display: flex;
                flex-direction: column;
                color: #7D7D7D;
                font-size: 14px;
                gap: 4px;

                svg {
                    margin-top: 7px;
                }
            }
        }
    }

    &.orderInfo {
        .order-info-price {
            font-size: 24px;
            font-weight: 700;
            line-height: 21px;
        }

        .cart-item-picture {
            width: 80px;
            height: 116px;

            img {
                object-fit: cover;
            }
        }

        .cart-item-label {
            display: flex;
            flex-direction: column;
            gap: 5px;

            h3 {
                margin: 0;
                line-height: 20px;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: -0.05em;
            }

            .price-bottom {
                margin-top: 10px;
            }

            .cart-item-caption {

                span {
                    margin: 0;
                    font-weight: 400;
                }
            }
        }
    }
}


.order-info {
    padding: 18px 16px;
    background-color: #363636;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: white;
    margin-top: 30px;

    span {
        color: #7D7D7D;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;

    }

    h4,
    h3 {
        margin: 0
    }

    h4 {
        font-size: 18px;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29.05px;
    }
}