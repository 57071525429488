.card-block-element {
    padding: 15px;
    border-radius: 14px;
    background-color: #363636;
    margin-top: 20px;

    &.delivery-info {

        h3 {
            margin: 0;
            margin-bottom: 16px;
            color: #D9D9D9;
        }

        .wrapper {


            p,
            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                padding: 0;
                margin: 0;
            }

            p {
                margin-bottom: 5px;
                color: #7D7D7D;
            }

            span {
                color: #D9D9D9;
                margin-bottom: 10px;


            }
        }
    }
}

.count-block {
    margin-top: 20px;

    .count-block-gray {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 15px;
        gap: 5px;
    }

    .white-weight-text {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
        color: white;
    }

    .gray-text {
        color: #7D7D7D;
        font-size: 18px;
        font-weight: 400;
        line-height: 21.78px;

        &.end {
            text-align: end;
        }
    }

    .white-big-text {
        font-size: 23px;
        font-weight: 700;
        line-height: 29px;
        color: white;
        margin: 0;
        margin-top: 20px;
    }
}