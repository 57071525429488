.cart-container {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 13px 10px;
    background-color: #282828;
    justify-content: space-between;

    .price-block {
        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 21px;
            white-space: nowrap;
        }

    }
}

.counter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    background: #4A4A4A;
    border-radius: 10px;
    padding: 15px;
}

.counter-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0;
}

.counter-icon {
    width: 16px;
    height: 16px;
}

.counter-value {
    color: white;
    font-weight: bold;
    min-width: 2rem;
    text-align: center;
}

.add-button {
    background: #2AAB3F;
    color: white;
    border: none;
    border-radius: 6px;
    width: 100%;
    max-width: 269px;
    height: 60px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s;

    font-size: 18px;
    font-weight: 500;
    line-height: 21px;

    &.full {
        max-width: unset;
    }

    .price-span {
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;

    }

    &.order-button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price-span {
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
        }
    }
}



.out-of-stock-button {
    background: #cccccc;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 0.75rem 1.5rem;
    cursor: not-allowed;
    font-weight: bold;
}


.price-block {
    display: flex;
    gap: 7px;
    align-items: center;

    h3 {
        font-size: 32px;
        font-weight: 700;
        line-height: 21px;
        margin: 0;
        color: #FFFFFF;
    }

    .old-price {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-decoration-line: line-through;
        text-decoration-skip-ink: none;
        color: #7D7D7D;
    }

    .percent {
        background-color: #FD3979;
        font-family: Inter;
        font-size: 10px;
        font-weight: 500;
        line-height: 21px;
        padding: 0 8px;
        color: white;
        border-radius: 6px;
    }
}