/* /////// FLEX */

.flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.align-center {
        align-items: center;
    }

    &.align-end {
        align-items: flex-end
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.gap-5 {
        gap: 5px;
    }

    &.gap-10 {
        gap: 10px;
    }

    &.gap-15 {
        gap: 15px;
    }

    &.gap-20 {
        gap: 20px;
    }

    &.gap-25 {
        gap: 25px;
    }

    &.gap-30 {
        gap: 30px;
    }

    &.gap-35 {
        gap: 30px;
    }
}

/* /////// MT */

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

/* /////// FONT */

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-24 {
    font-size: 24px;
}

.underline {
    text-decoration: underline;
}



/* /////// COLOR */

.gray-d9 {
    color: #d9d9d9;
}

.gray-7d {
    color: #7D7D7D;
}

/* ///// width */

.width-100 {
    width: 100%;
}