.terms {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #D9D9D9;

    padding-left: 20px;

    a {
        display: block;
        color: white;
        text-decoration: underline;
    }

    span+a {
        margin-top: 10px;
    }


    span {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
    }
}